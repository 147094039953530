import 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@3.0.1/dist/cookieconsent.umd.js';



// Enable dark mode
document.documentElement.classList.add('cc--darkmode');

CookieConsent.run({
    cookie: {
        name: 'fca_cookie',
        // domain: location.hostname,
        // path: '/',
        // sameSite: "Lax",
        // expiresAfterDays: 365,
    },
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true,
            enabled: true
        },
        analytics: {
            enabled: true,
            autoClear: {
                cookies: [
                    {
                        name: /^(_ga|_gid)/, //if this doesnt work change it as per docs
                    }
                ]
            },
            services: {
                ga: {
                    label: 'Almacenamiento para Analytics',
                    onAccept: () => {
                        gtag('consent', 'update', {
                            'analytics_storage': 'granted'
                        });
                        gtag('consent', 'update', {
                            'ad_storage': 'granted'
                        });
                    },
                    onReject: () => {
                        gtag('consent', 'update', {
                            'analytics_storage': 'denied'
                        });
                        gtag('consent', 'update', {
                            'ad_storage': 'denied'
                        });
                    }
                }
            }
        },
        ads: {
            enabled: true,
            autoClear: {
                cookies: [
                    {
                        name: /^(_ga|_gid)/
                    }
                ]
            }
        }
    },
    // onConsent() {
    //     if (CookieConsent.acceptedCategory('analytics')) {
    //         //// Analytics category enabled
    //         console.log('Enable analytics consent categoria');
    //         // gtag('consent', 'update', {
    //         //     'analytics_storage': 'granted'
    //         // });
    //         const preferences = CookieConsent.getUserPreferences();
    //         const cookie = CookieConsent.getCookie();
    //         // console.log(userPreference, 'User prefreferences');
    //         // console.log(cookie, 'COOKIE')

    //         const userConsent = {
    //             consentId: cookie.consentId,
    //             acceptType: preferences.acceptType,
    //             acceptedCategories: preferences.acceptedCategories,
    //             rejectedCategories: preferences.rejectedCategories
    //         };
    //         console.log(userConsent);
    //     }

    //     if (CookieConsent.acceptedService('Google Analytics', 'analytics')) {
    //         // Google Analytics enabled
    //         console.log('Enable analytics servicio');

    //     }
    // },
    language: {
        default: "es",
        autoDetect: "browser",
        translations: {
            en: {
                consentModal: {
                    title: "Gracias por estar acá! Conoce nuestra politica de cookies",
                    description: "Utilizamos cookies para mejorar la experiencia de usuario al navegar por nuestro sitio web.",
                    acceptAllBtn: "Aceptar Todo",
                    acceptNecessaryBtn: "Rechazar Todo",
                    showPreferencesBtn: "Gestionar Preferencias",
                    footer: "<a href=\"#link\">Política de Privacidad</a>\n<a href=\"#link\">Términos y condiciones</a>"
                },
                preferencesModal: {
                    title: "Centro de preferencias de consentimiento",
                    acceptAllBtn: "Aceptar Todo",
                    acceptNecessaryBtn: "Rechazar Todo",
                    savePreferencesBtn: "Guardar Preferencias",
                    closeIconLabel: "Cerrar ventana",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Uso de Cookies",
                            description: "Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas un sitio web. Estas cookies permiten que el sitio recuerde tus acciones y preferencias (como inicio de sesión, idioma, tamaño de fuente, entre otras configuraciones) durante un período de tiempo, para que no tengas que volver a configurarlas cada vez que navegues por el sitio o vuelvas a visitarlo."
                        },
                        {
                            title: "Cookies Estrictamente Necesarias <span class=\"pm__badge\">Siempre Habilitado</span>",
                            description: "Estas cookies son esenciales para que puedas moverte por el sitio web y usar sus características, como acceder a áreas seguras del sitio.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Cookies de Google Analytics",
                            description: "Utilizamos Google Analytics para analizar el comportamiento de los usuarios en nuestro sitio web y mejorar su experiencia. Google Analytics es una herramienta de análisis web que nos ayuda a entender cómo los visitantes interactúan con nuestro sitio, qué páginas visitan, el tiempo que pasan en ellas y otros datos relacionados con su navegación.",
                            linkedCategory: "analytics"
                        },
                        {
                            title: "Más Información",
                            description: "Para cualquier consulta en relación a la política de cookies y sus elecciones, por favor consulte <a class=\"cc__link\" href=\"#yourdomain.com\">aquí</a>."
                        }
                    ]
                }
            }
        }
    }
});